//
// Gatsby Tinongo template for Contentful content model: 'Static'
//

import React from 'react';
import { graphql } from 'gatsby';
import { html } from '../utils';

import AppShell from '../components/app-shell';
import Content from '../components/content';
import Section from '../components/section';
import SEO from '../components/seo';

export default function Static({ data }) {
  const { titel, metaDescription, inhalt } = data.contentfulStatic;

  const header = (
    <header>
      <h1 className="pageTitle">{titel}</h1>
    </header>
  );

  const content = (
    <>
      {inhalt && (
        <Section fullWidth>
          <div
            className="bodyText"
            dangerouslySetInnerHTML={html(inhalt.inhalt)}
          />
        </Section>
      )}
    </>
  );

  return (
    <>
      <SEO title={titel} description={metaDescription} />
      <AppShell>
        <Content>
          <article>
            {header}
            {content}
          </article>
        </Content>
      </AppShell>
    </>
  );
}

export const query = graphql`
  query($slug: String!) {
    contentfulStatic(slug: { eq: $slug }) {
      titel
      metaDescription
      inhalt {
        inhalt
      }
    }
  }
`;
